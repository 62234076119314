/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";

import "./DHLFrameFooter.scss";
import { DHLButton, DHLIcon } from "@gkuis/gkp-base-widgets";
import { useUID } from "react-uid";
import { useTranslation } from "react-i18next";
import { LanguageSwitch } from "../../molecules/LanguageSwitch/LanguageSwitch";
import { FooterNavigationEntry } from "../../../types/Navigation";

type DHLFooterProps = {
  navigation: FooterNavigationEntry[];
  languageSwitchUrl: string | null;
}

export const DHLFrameFooter = observer(({navigation, languageSwitchUrl}: DHLFooterProps) => {
  const {t} = useTranslation();

  return <footer className="dhlFrameFooter">
    {languageSwitchUrl !== null && <LanguageSwitch languageSwitchUrl={languageSwitchUrl} />}
    <FooterScrollToTop />
    <div className="branding-footer"><DHLIcon name={""} icon={"dhl-group-logo-rgb-black"} /></div>
    <nav className="nav-footer">
      {navigation.map(((value, index) => {
            if (value.hrefLocalizationKey !== undefined) {
              return <a className={"entry"} href={t(value.hrefLocalizationKey)} target={value.target} key={index}>{t(value.key)}</a>;
            } else {
              return <a
                  role="button" tabIndex={0}
                  key={index}
                  className={"entry"}
                  onClick={value.onClick}
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "Space") {
                      value.onClick();
                    }
                  }}
              >{t(value.key)}</a>;
            }
          }
      ))}
    </nav>
    <div className="footer-copyright">{t("copyright")}</div>
  </footer>;
});

const FooterScrollToTop = () => {
  return <div className="toTop">
    <DHLButton name={"hf-f" + useUID()} // remove when truly optional in DHLButton
               size={"lg"}
               type={"secondary"}
               iconPosition={"icon"} // GKDS make it work with secondary style, remove override in scss
               icon={"arrow-up"}
               onClick={(e) => {
                 e.currentTarget.blur();
                 window.scrollTo({top: 0, behavior: "smooth"});
               }}
    />
  </div>;
};

