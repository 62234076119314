/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { FooterNavigationEntry } from "../../../types/Navigation";
import { DHLFrameFooter } from "../DHLFrameFooter/DHLFrameFooter";
import { reaction } from "mobx";
import { useTranslation } from "react-i18next";
import { AuthenticationManagerContext } from "@gkuis/gkp-base-widgets";
import moment from "moment";

const filterNavigationItems = (scopes: string[], navItems: FooterNavigationEntry[]): FooterNavigationEntry[] => {
  return navItems
      .filter(item => item.scopes === undefined || item.scopes.length === 0 || item.scopes.some(requiredScope => scopes.includes(requiredScope)));
};

export type RootProps = {
  authenticationManager: AuthenticationManager;
  navigation: FooterNavigationEntry[];
  languageSwitchUrl: string | null;
}

export const Root = observer(({authenticationManager, navigation, languageSwitchUrl}: RootProps) => {
  const {i18n} = useTranslation();

  const [scopes, setScopes] = useState<string[]>([]);
  useEffect(() => reaction(
      () => authenticationManager.authenticatedSubject,
      () => authenticationManager.getScopes().then(setScopes).catch(() => setScopes([])),
      {fireImmediately: true}
  ), [authenticationManager, setScopes]);

  useEffect(() => reaction(
      () => authenticationManager.language,
      (language) => {
        i18n.changeLanguage(language);
        moment.locale(authenticationManager.language.startsWith("en") ? "en-gb" : "de");
      },
      {fireImmediately: true}
  ), [authenticationManager, i18n]);

  const filteredNavigation: FooterNavigationEntry[] = filterNavigationItems(scopes, navigation);

  return (
      <AuthenticationManagerContext.Provider value={authenticationManager}>
        <BrowserRouter>
          <DHLFrameFooter navigation={filteredNavigation} languageSwitchUrl={languageSwitchUrl}/>
        </BrowserRouter>
      </AuthenticationManagerContext.Provider>
  );
});
