/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
import { FetchAdapter, logger } from "@gkuis/gkp-base-widgets";
import { Language } from "@gkuis/gkp-authentication";

const LOG_MODULE = "LanguageSwitchClient";

export class LanguageSwitchClient {

  constructor(private readonly uri: string, private readonly fetchAdapter: FetchAdapter) {}

  async saveLanguage(language: Language): Promise<void> {
    logger.log(LOG_MODULE, `switching language to ${language}`);

    try {
      const wrappedResponse = await this.fetchAdapter.put(this.uri, {body: JSON.stringify({language: language})});
      if(wrappedResponse.ok) {
        logger.log(LOG_MODULE, `successfully switched language to ${language}`);
      }
    } catch (errors) {
      logger.log(LOG_MODULE, `failed switching language to ${language}`);
      throw errors;
    }
  }
}