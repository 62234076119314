/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
      fallbackLng: "de",
      // debug: true,
      resources: {
        //use require instead of many 'import de from "./locales/de.json";' above. just prettier than imports
        de: {
          translation: require("./locales/de.json")
        },
        en: {
          translation: require("./locales/en.json")
        }
      },

      interpolation: {
        escapeValue: false,
      }
    });

export default i18n;