/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {Language, logger} from "@gkuis/gkp-authentication";
import {useState} from "react";
import {LanguageSwitchClient} from "../../../clients/LanguageSwitchClient";
import {FetchAdapter, useAuthenticationManager} from "@gkuis/gkp-base-widgets";

export const LanguageSwitch = observer(({languageSwitchUrl}:{ languageSwitchUrl: string}) => {
  const {t} = useTranslation();
  const authenticationManager = useAuthenticationManager();
  const [languageSwitchClient] = useState(() => new LanguageSwitchClient(languageSwitchUrl, new FetchAdapter(authenticationManager)));
  const setLanguage = async (language: Language) => {
    if(authenticationManager.language === language) {
      return;
    }
    try {
      if(authenticationManager.authenticated) {
        await languageSwitchClient.saveLanguage(language);
        await authenticationManager.updateToken(-1);
      } else {
        authenticationManager.language = language;
      }
    } catch (error) {
      logger.error(`[LangSwitch] error switching language to ${language}: ${error}`);
    }
  };

  return (
      <div className="lang-switch">
        <div className="title">{t("languageSelection")}</div>
        <div className="langs">
          {(["de", "en"] as Language[])
              .map(language =>
                  <a
                      role="button" tabIndex={0}
                      key={language}
                      className={authenticationManager.language === language ? "active" : ""}
                      onClick={() => setLanguage(language)}
                      onKeyDown={(e) => {
                        if(e.code === "Enter" || e.code === "Space"){
                          setLanguage(language)
                        }
                      }}
                  >
                    {t(`languageSelection.${language}`)}
                  </a>
              )}
        </div>
      </div>
  );
});